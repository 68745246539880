import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../store/actions/actions';
import HeaderRun from './Header.run';
import {apiBaseURL} from '../../config.js';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import axios from "axios";
import {Redirect} from "react-router";

class Header extends Component {

    componentDidMount() {
        HeaderRun();
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize () {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    state = {
        response: '',
    };

    componentDidMount() {
        this.callApi()
            .then(res => this.setState(res))
            .catch(err => console.log(err));
    }

    callApi = async () => {
        const myHeaders = new Headers();
        myHeaders.append('Authorization', localStorage.jwtToken);

        const response = await fetch(apiBaseURL + '/api/users/currentUser', {
            method: 'GET',
            headers: myHeaders,
        });
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);

        let admin = body.roles.includes('ADMINISTRATOR');

        let data = {
            userName:  body.name,
            admin: admin,
            userRole: admin ? 'Admin' : 'User'
        };

        return data
    };

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    loginRedirect = () => {
        this.setState({
            loginRedirect: true
        })
    };

    logout = () => {
        let token = localStorage.jwtToken;
        if (token) {
            axios.post(apiBaseURL + '/api/auth/logout', {}, {
                headers: {
                    'Content-Type': 'application/v1+json',
                    'Authorization': localStorage.jwtToken
                }
            }).then(response => {
                localStorage.removeItem('jwtToken');
                this.loginRedirect();
            }).catch(err => {
                console.log(err);
            });
        } else {
            this.loginRedirect();
        }
    };


    render() {
        if(this.state.loginRedirect){
            return <Redirect push to="/login" />;
        }

        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */ }
                <nav className={"navbar topnavbar black-header"}>
                    { /* START navbar header */ }
                    <div className="navbar-header">
                        <a className="navbar-brand">
                            <div className="brand-logo">
                                <p><span className="header-hughes-color">Hughes </span><span className="header-marino-color">Marino </span><span className={"header-casper-color"}>Casper</span></p>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/Ghosty.png" alt="ghosty" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */ }

                    { /* START Left navbar */ }
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                            <a className="nav-link d-none d-md-block d-lg-block d-xl-block">
                                <img className='img-fluid ghosty-img' src="img/Ghosty.png" alt='App Logo' />
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                            <a href=""  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>

                    </ul>
                    { /* END Left navbar */ }
                    { /* START Right Navbar */ }
                    <ul className="navbar-nav flex-row">
                        { /* Search icon */ }
                        <li className="nav-item">
                            {/*<a href="/users" className="nav-link header-settings-icon" data-search-open="">*/}
                                <em className="icon-user nav-link"/>
                            {/*</a>*/}
                        </li>
                        { /* START Offsidebar button */ }
                        <li className="nav-item">
                            {/*<a className="nav-link" href="" onClick={this.toggleOffsidebar}>*/}
                            <p className="header-user-name">{this.state.userName}</p>
                            <p className="header-user-role">{this.state.userRole}</p>
                            {/*</a>*/}
                        </li>
                        { /* END Offsidebar menu */ }
                        <li className="nav-item">
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className={"nav-link m-0"}>
                            <DropdownToggle color={"nav"} className={"p-0"}>
                                <em className="icon-settings nav-item"/>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem href="/global-user-settings">Edit your account
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={this.logout}>
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        </li>
                    </ul>
                    { /* END Right Navbar */ }
                </nav>
                { /* END Top Navbar */ }
            </header>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
