const UserMenu = [
    {
        name: 'Home',
        path: '/home',
        icon: 'icon-home',
    },
    {
        name: 'Email Management',
        icon: 'icon-envelope',
        submenu: [{
            name: 'Email Templates',
            path: '/email-templates',
        },
        ]
    },
    {
        name: 'Monitor Progress',
        icon: 'fas fa-heartbeat',
        submenu: [
            {
                name: 'Active',
                path: '/active',
            },
            {
                name: 'Complete',
                path: '/complete',
            },
            {
                name: 'Errors',
                path: '/errors',
            }
        ]
    }
];

export default UserMenu;
