
// base url used primarily for all api calls made through out site

var apiBaseURL;

if (process.env.REACT_APP_API_SERVER_URL) {
    apiBaseURL = process.env.REACT_APP_API_SERVER_URL;
    console.log('apiBaseURL: ' + apiBaseURL);
} else {
    apiBaseURL = 'http://localhost:8080';
    console.log('default localhost url');
}

export var apiBaseURL;