const Menu = [
    {
        name: 'Home',
        path: '/home',
        icon: 'icon-home',
    },
    {
        name: 'Email Management',
        icon: 'icon-envelope',
        submenu: [{
                name: 'Email Templates',
                path: '/email-templates',
            },
        ]
    },
    {
        name: 'Monito' +
            'r Progress',
        icon: 'fas fa-heartbeat',
        submenu: [
            {
                name: 'Active',
                path: '/active',
            },
            {
                name: 'Completed',
                path: '/complete',
            },
            {
                name: 'Errors',
                path: '/errors',
            }
        ]
    },
    {
        name: 'Settings',
        icon: 'fas fa-wrench',
                submenu: [{
                name: 'Broker Profiles',
                path: '/broker-profiles',
            },
            {
                name: 'Global Settings',
                path: '/global-settings',
            },
            {
                name: 'Users',
                path: '/users',
            }
        ]
    }
];

export default Menu;
